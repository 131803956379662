<template>
  <div class="refresh-activities-wrapper">
    <img style="width: 100%; margin-bottom: 20px;" src="/evrei.png" />
    <div>
      <strong>Mode: </strong>
      <span v-if="mode === 'all'">All</span>
      <span v-else-if="mode === 'filtered'">Filtered</span>
      <span v-else-if="mode === 'selected'">Selected</span>
    </div>
    <div><strong>Platform:</strong> {{ platform }}</div>
    <div v-if="startDate && mode !== 'selected'"><strong>Start Date:</strong> {{ startDate }}</div>
    <div v-if="endDate && mode !== 'selected'"><strong>End Date:</strong> {{ endDate }}</div>
    <div v-if="filterModelString"><strong>Filters:</strong> {{ filterModelString }}</div>
    <div><strong>Activities Count:</strong> {{ activitiesCount }}</div>
    <div v-if="!refreshed">
      <button id="refresh" v-on:click="refreshFree" :class="['button-outline-blue', 'item']">
        Refresh
      </button>
    </div>
    <div class="mt-3" v-else>
      Selected activities have been sent for processing!
    </div>
  </div>
</template>
<script>
import mtApi from '@/agGridV2/helpers/mt-api.helper'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      startDate: null,
      endDate: null,
      platform: null,
      activitiesCount: 0,
      activitiesIds: [],
      refreshed: false,
      gridApi: null,
      mode: 'all',
      filterModel: null,
      filterModelString: '',
      loading: false
    }
  },
  methods: {
    ...mapGetters([
      'getLeadCenterDate',
      'getSelectedPlatform'
    ]),
    async refreshFree() {
      await mtApi.refreshForProcess({
        ids: this.activitiesIds,
        platform: this.getSelectedPlatform()
      })
      this.$emitter.emit('activities_refresh_start', {
        platform: this.getSelectedPlatform()._id,
        count: this.activitiesCount
      })
      this.refreshed = true
    }
  },
  async mounted() {
    this.loading = true
    const date = this.getLeadCenterDate()
    const platform = this.getSelectedPlatform()
    this.startDate = date?.start?.code
    this.endDate = date?.end?.code
    this.platform = platform.url
    this.gridApi = this.$attrs.gridApi
    if (this.gridApi) {
      const selectedNodes = this.gridApi.getSelectedNodes()
      for (const node of selectedNodes) {
        if (node.data.status === 'imported') {
          node.setSelected(false)
          continue
        }
        this.activitiesIds.push(node.data._id)
      }
      if (this.activitiesIds.length > 0) {
        this.mode = 'selected'
        this.activitiesCount = this.activitiesIds.length
      } else {
        const filterModel = this.gridApi.getFilterModel()
        if (filterModel && Object.keys(filterModel).length > 0) {
          this.mode = 'filtered'
          this.filterModel = filterModel
          for (const filter of Object.keys(filterModel)) {
            this.filterModelString += `key: ${filter}; `
            if (filterModel[filter]['values'] && filterModel[filter]['values'].length > 0) {
              this.filterModelString += `values: ${filterModel[filter]['values'].join(', ')}`
            } else if (filterModel[filter]['filter']) {
              this.filterModelString += `value: ${filterModel[filter]['filter']}`
            }
            this.filterModelString += `; `
          }
        }
      }
    }
    this.loading = false
  }
}
</script>
<style lang="scss" scoped>
.refresh-activities-wrapper {
  padding: 20px;
  div {
    margin-bottom: 5px;
  }
  .attention {
    border: 1px solid red;
    border-radius: 10px;
    padding: 10px;
    font-weight: 500;
  }
}
</style>
